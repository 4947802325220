import React from 'react';
import {Nav, Navbar, NavbarBrand, NavItem, Collapse, NavbarToggler, Card, Button} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import { useAppContext } from '../libs/ContextLib';
import {Auth} from 'aws-amplify';
//import Spinner from './SpinnerComponent';


export default function Login(){

    const { userHasAuthenticated } = useAppContext();
    //const [isUserLoggingOut, setUserLoggingOut] = useState();

    async function handleLogout(event) {
        event.preventDefault();
        try{
           //setUserLoggingOut("loggingout");
            await Auth.signOut();
            userHasAuthenticated(false);
        }
        catch (e){
            //setUserLoggingOut("Failedloggingout");
            alert (e.message);
        }
       
    }
    return(
        <React.Fragment>
                <Card>
                    <div className="container" style={{ background:"white" }}>
                        <div className="row justify-content-around">
                            <div className="col-2 mr-auto">
                                <NavbarBrand>
                                    <img src="asset/images/sepchlogo.png" height="120" width="100"  className="img-fluid" alt="South East Personal Care Home"></img>
                                </NavbarBrand>
                            </div>
                            <div className="col-8 mr-auto align-text-center">
                                <img src="asset/images/sepchname.png" height="80%" width="80%" className="img-fluid" alt="South East Personal Care Home"></img>
                            </div>
                        </div>
                    </div>
                </Card>
                <Navbar dark expand="md">
                    <div className="container">
                        <NavbarToggler ></NavbarToggler>
                        <Collapse navbar>
                            <Nav navbar>
                                <NavItem className="navitem">
                                    <NavLink className="nav-link" to='/internalhome' style={{ color:"white" }}>Home</NavLink>
                                </NavItem>

                                <NavItem className="navitem">
                                    <NavLink className="nav-link"  to='/internaljob' style={{ color:"white" }}>Jobs</NavLink>
                                </NavItem>
                                <NavItem className="navitem">
                                    <NavLink className="nav-link"  to='/internalbulletin' style={{ color:"white" }}>Bulletin</NavLink>
                                </NavItem>
                            </Nav>
                        </Collapse>
                        <Button className="ml-auto align-self-center" onClick={handleLogout} style={{ color:"white" }}><i className="fa fa-sign-out"></i> Logout</Button>
                    </div>  
                </Navbar>
                
        </React.Fragment>
    );
};
