import React, { useState } from 'react';
import { Button, FormGroup, FormControl } from 'react-bootstrap';
import { Breadcrumb, BreadcrumbItem, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../libs/ContextLib';
import Spinner from './SpinnerComponent';


export default function Login() {

  const { userHasAuthenticated } = useAppContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isUserLoggingIn, setUserLoggingIn] = useState();
  

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();

    try {
      setUserLoggingIn("loggingin");
      await Auth.signIn(email, password);
      userHasAuthenticated(true);
      
    }
    catch (e) {
      setUserLoggingIn("failedlogin");
      //alert (e.message);
    }
  }

  return (
    <div className="container">
      <div className="row">
        <Breadcrumb>
            <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
            <BreadcrumbItem active>Staff Page</BreadcrumbItem>
        </Breadcrumb>
        <div className="col-12">
            <h3>Staff Page</h3>
            <hr />
        </div>               
      </div>
      <div className="Login col-12">
        <form onSubmit={handleSubmit}>
          {isUserLoggingIn === "loggingin" ? <Spinner /> : ""}
          {isUserLoggingIn === "failedlogin" ? <p style={{color:"red"}}>Incorrect Username or Password</p> : ""}
          <FormGroup controlId="email" bsSize="large">
            <Label>Email</Label>
            <FormControl autoFocus type="email" value={email} onChange={e => setEmail(e.target.value)}/>
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <Label>Password</Label>
            <FormControl value={password} onChange={e => setPassword(e.target.value)} type="password"/>
          </FormGroup>
          <Button block bsSize="large" variant="success" disabled={!validateForm()} type="submit"><i className="fa fa-sign-in"></i> Login</Button>
        </form>
      </div>
      <br></br>
    </div>
  );
}