import React, {Component} from 'react';
import {Nav, Navbar, NavbarBrand, NavItem, Collapse, NavbarToggler, Badge,
        DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, Card} from 'reactstrap';
import {NavLink} from 'react-router-dom';


class Header extends Component{

    constructor(props){
        super(props);
        this.toggleDropDownAbout = this.toggleDropDownAbout.bind(this);
        this.toggleDropDownResident = this.toggleDropDownResident.bind(this);
        this.toggleDropDownRecreation = this.toggleDropDownRecreation.bind(this);
        this.onMouseEnterAbout = this.onMouseEnterAbout.bind(this);
        this.onMouseLeaveAbout = this.onMouseLeaveAbout.bind(this);
        this.onMouseEnterResident = this.onMouseEnterResident.bind(this);
        this.onMouseLeaveResident = this.onMouseLeaveResident.bind(this);
        this.onMouseEnterRecreation = this.onMouseEnterRecreation.bind(this);
        this.onMouseLeaveRecreation = this.onMouseLeaveRecreation.bind(this);
        this.state={
            isNavOpen:false,
            isDropDownOpenAbout:false,
            isDropDownOpenResident:false,
            isDropDownOpenRecreation:false
        };
    }

    toggleNav = () => {
        this.setState({
            isNavOpen: !this.state.isNavOpen
        });
    }

    toggleDropDownAbout(){
        this.setState({
            isDropDownOpenAbout: !this.state.isDropDownOpenAbout
        });
    }

    toggleDropDownResident(){
        this.setState({
            isDropDownOpenResident: !this.state.isDropDownOpenResident
        });
    }

    toggleDropDownRecreation(){
        this.setState({
            isDropDownOpenRecreation: !this.state.isDropDownOpenRecreation
        });
    }

    onMouseEnterAbout(){
        this.setState({isDropDownOpenAbout: true})
    }

    onMouseLeaveAbout(){
        this.setState({isDropDownOpenAbout: false})
    }

    onMouseEnterResident(){
        this.setState({isDropDownOpenResident: true})
    }

    onMouseLeaveResident(){
        this.setState({isDropDownOpenResident: false})
    }

    onMouseEnterRecreation(){
        this.setState({isDropDownOpenRecreation: true})
    }

    onMouseLeaveRecreation(){
        this.setState({isDropDownOpenRecreation: false})
    }

    toggleLoginModal = () =>{
        this.setState({
            isLoginModalOpen:!this.state.isLoginModalOpen
        });
    }

    render(){

        return(
            <React.Fragment>
                <Card>
                    <div className="container" style={{ background:"white" }}>
                        <div className="row justify-content-around">
                            <div className="col-2 mr-auto">
                                <NavbarBrand>
                                    <img src="https://sepch.s3.us-east-2.amazonaws.com/sepchlogo.png" height="120" width="100"  className="img-fluid" alt="South East Personal Care Home"></img>
                                </NavbarBrand>
                            </div>
                            <div className="col-8 mr-auto align-text-center">
                                <img src="https://sepch.s3.us-east-2.amazonaws.com/sepchname.png" height="80%" width="80%" className="img-fluid" alt="South East Personal Care Home"></img>
                            </div>

                        </div>
                    </div>
                </Card>
                <Navbar dark expand="lg">
                    <div className="container">
                        <NavbarToggler onClick={this.toggleNav}></NavbarToggler>
                        <Collapse isOpen ={this.state.isNavOpen} navbar>
                            <Nav navbar>
                                <UncontrolledDropdown nav inNavbar onMouseOver={this.onMouseEnterAbout} onMouseLeave={this.onMouseLeaveAbout} isOpen={this.state.isDropDownOpenAbout} toggle={this.toggleDropDownAbout}>
                                    <DropdownToggle nav caret style={{ color:"white", fontWeight:"bold"}}>About Us</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem >
                                            <NavLink className="nav-link" to='/mission' style={{ color:"black" }}>Mission Statement & Values</NavLink>
                                        </DropdownItem>
                                        <DropdownItem >
                                            <NavLink className="nav-link" to='/login' style={{ color:"black"}}>Staff Page</NavLink>
                                        </DropdownItem>
                                        <DropdownItem >
                                            <NavLink className="nav-link" to='/contactus' style={{ color:"black"}}>Contact Us</NavLink>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                <UncontrolledDropdown nav inNavbar onMouseOver={this.onMouseEnterResident} onMouseLeave={this.onMouseLeaveResident} isOpen={this.state.isDropDownOpenResident} toggle={this.toggleDropDownResident}>
                                    <DropdownToggle nav caret style={{ color:"white", fontWeight:"bold"}}>Residents</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem >
                                            <NavLink className="nav-link" to='/admission' style={{ color:"black"}}>Admission</NavLink>
                                        </DropdownItem>
                                        <DropdownItem >
                                            <NavLink className="nav-link" to='/accessibility' style={{ color:"black"}}>Accessibility</NavLink>
                                        </DropdownItem>
                                        <DropdownItem >
                                            <NavLink className="nav-link" to='/activities' style={{ color:"black"}}>Activities</NavLink>
                                        </DropdownItem>
                                        <DropdownItem >
                                            <NavLink className="nav-link" to='/covid-19-updates' style={{ color:"black"}}>COVID & Infection Control Updates</NavLink>
                                        </DropdownItem>
                                        { 
                                            /*
                                            <DropdownItem >
                                                <NavLink className="nav-link" to='/celebrationoflife' style={{ color:"black"}}>Celebration Of Life</NavLink>
                                            </DropdownItem>
                                            */
                                        }
                                        <DropdownItem >
                                            <NavLink className="nav-link" to='/sendwellness' style={{ color:"black"}}>Wellness Messages</NavLink>
                                        </DropdownItem>
                                        <DropdownItem >
                                            <NavLink className="nav-link" to='/visitbooking' style={{ color:"black"}}>Visit Booking</NavLink>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                
                                <UncontrolledDropdown nav inNavbar onMouseOver={this.onMouseEnterRecreation} onMouseLeave={this.onMouseLeaveRecreation} isOpen={this.state.isDropDownOpenRecreation} toggle={this.toggleDropDownRecreation}>
                                    <DropdownToggle nav caret style={{ color:"white", fontWeight:"bold"}}>Recreation</DropdownToggle>
                                    <DropdownMenu>
                                        {
                                        <DropdownItem >
                                            <NavLink className="nav-link" to='/lifeatsepch' style={{ color:"black"}}>Life At Southeast</NavLink>
                                        </DropdownItem> 
                                        }
                                        <DropdownItem >
                                            <NavLink className="nav-link" to='/recreationevents' style={{ color:"black"}}>Recreation Events</NavLink>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            
                    
                                <NavItem className="navitem">
                                    <NavLink className="nav-link"  to='/services' style={{ color:"white", fontWeight:"bold"}}>Services</NavLink>
                                </NavItem>
                                <NavItem className="navitem">
                                    <NavLink className="nav-link"  to='/careers' style={{ color:"white", fontWeight:"bold"}}>Careers</NavLink>
                                </NavItem>
                                <NavItem className="navitem">
                                    <NavLink className="nav-link" to='/volunteer' style={{ color:"white", fontWeight:"bold"}}>Volunteer</NavLink>
                                </NavItem>
                                {/*
                                <NavItem className="navitem">
                                    <NavLink className="nav-link" to='/donation' style={{ color:"white", fontWeight:"bold"}}>Donation</NavLink>
                                </NavItem>*/
                                }
                            </Nav>
                        </Collapse>
                        {/*<Badge className="ml-auto align-self-center" color="warning"><a href="upload/files/Residents-Family/SEPCH-Covid-Updates-May17-2021.pdf" target="_blank" rel="noopener noreferrer" style={{ color:"white" }}>COVID-19 <br></br>Latest Updates</a></Badge>*/}
                    </div>  
                </Navbar>
            </React.Fragment>
        );
    }
};

export default Header;