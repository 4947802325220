import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem, Media } from 'reactstrap';
import { Link } from 'react-router-dom';


class Mission extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Mission Statement & Values </BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Mission Statement & Value</h3>
                        <hr />
                    </div>               
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <Media heading>Overview</Media>
                            <p>Southeast Personal Care Home (SEPCH) is an 80 bed Personal Care Home located in the Fairfield Park neighborhood in Winnipeg.  This facility is nestled in a quiet residential area, next door to Southeast College. SEPCH officially opened it's doors Tuesday, June 21st, 2011. 
                            Southeast Personal Care Home has been designated with a number of residential units.  Each unit is called a "house" and is composed of 15-20 single private rooms with in-suite bathrooms.  Each house has its own dining room and quiet room.  Various activities will be conducted in the dining room after meal times.</p>
                        </Media>
                    </Media>
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <Media heading>Values Statement</Media>
                            <p>Southeast Personal Care Home is First Nation-owned and operated and respects the holistic Indigenous values and cultural traditions that nurture the health and well-being of each person.  We are committed to serving them, their families, the volunteers, the staff and community with dignity and respect.  Southeast Personal Care Home will provide accountability, transparency, service of excellence, and organizational success.</p>
                        </Media>
                    </Media>
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <Media heading>Vision Statement</Media>
                            <p>To be the Personal Care Home services provider of choice for Indigenous people.</p>
                        </Media>
                    </Media>
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <Media heading>Resident and Family Centered Care & Services</Media>
                            <p>Quality of life for each resident will be supported by culturally appropriate setting and programs, which consider each resident's culture, past experience, personal values, and interest.

Individual goals, strengths and limitations, and specific health needs influence the services and programs delivered to each resident.

Uniqueness and dignity of each resident is recognized, supported, respected and protected.

Culturally based activities allow residents an opportunity to learn more about or honor their culture.  We encourage a healthy respect for other's right to practice, as they believe.  Teachings offer the opportunity to share and learn traditional ways..</p>
                        </Media>
                    </Media>
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <Media heading>Southeast Personal Care Home Offers</Media>
                            <ul>
                                <li>Private rooms with an external view and spacious washrooms in an energy efficient facility.</li>
                                <li>Tasty meals and snacks prepared on site that accommodate specialized diets.</li>
                                <li>Inter-generational activities reflecting the diverse cultures and heritage of residents.</li>
                                <li>Opportunity to attend many different events and recreational activities throughout Winnipeg. </li>
                            </ul>
                        </Media>
                    </Media>
                </div>
            </div>
        );
    };
}

export default Mission;