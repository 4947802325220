import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem, Media } from 'reactstrap';
import { Link } from 'react-router-dom';


class ResidentSafety extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Residents Safety</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Residents Safety</h3>
                        <hr />
                    </div>               
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <p>Sometimes something unexpected can happen to a patient, resident or client that has caused them unintended serious harm. 
                                When the event is a result of healthcare provided and not due to the individual’s illness or the usual risks in treating the disease, it is called a critical incident.</p>
                            <p>Have you witnessed a critical incident? We need to know if an event like this happens so that we can learn from it and make our patient care system safer.</p>
                            <p>If you have witnessed a critical incident, call our 24-hour CRITICAL INCIDENT LINE at <a href="tel:2042697111">204-269-7111</a></p>
                            <p>For more information on Patient Safety and Critical Incidents, please visit the
                               <a href="https://wrha.mb.ca/quality-improvement/"> WRHA Quality Website</a>
                            </p>                        
                       </Media>
                    </Media>
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <Media heading>Protection For Persons In Care Act</Media>
                            <p>Enacted in May 2001, the Protection for Persons in Care Act enforces the protection of persons in personal care homes and hospitals from mistreatment- physical, sexual, mental, emotional or financial. This legislation creates a formal process for reporting, investigating, and resolving allegations and suspicions of abuse in health care settings.</p>
                            <p>Operators of health care facilities must maintain a reasonable level of safety and protect patients form abuse. Under the legislation, concerned citizens and staff working in health facilities have a duty to report suspected abuse or the likelihood of abuse by anyone to the Minister of Health or his designate at the Protection for Persons in Care Office.</p>
                            <p>For more information on the Protection For Persons in Care Office, please see the pamphlet found in the back of this handbook.</p>                        
                            <p>Residents or their family members may contact the Protection for Persons in Care Office as follows:</p>
                            <h7>Access Line: <a href="tel:204-786-7132">204-786-7132</a> or toll free at <a href="tel:1-800-855-0511"> 1-800-855-0511</a><br></br></h7>
                            <h7>Fax: 204-775-8055<br></br></h7>
                            <h7>Email: <a href="mailto:protection@health.gov.mb.ca">protection@health.gov.mb.ca</a><br></br></h7>
                            <h7>Website: <a href="https://www.gov.mb.ca/health/protection/">https://www.gov.mb.ca/health/protection/</a><br></br><br></br></h7>
                        </Media>
                    </Media>
                </div>
                
            </div>
        );
    };
}

export default ResidentSafety;