import React, {Component} from 'react';
import {Button, Breadcrumb, BreadcrumbItem, Media} from 'reactstrap';
import {Link} from 'react-router-dom';


class InternalHome extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/internalhome">SEPCH</Link></BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Staff Home Page</h3>
                        <hr />
                    </div>  
                    <div className="col-12 mt-5">
                        <Media>
                            <Media body className="ml-5">
                                <h6>Southeast Personal Care Home Employee Handbook - Revised June 2020</h6>
                                <Link to="upload/files/EmployeeHandbook_2020.pdf" target="_blank" rel="noopener noreferrer">
                                    <Button>
                                        <i className="fa fa-download"></i> Download Here
                                    </Button>
                                </Link>
                            </Media>
                        </Media>
                    </div>              
                </div>
            </div>
        );
    };
}

export default InternalHome;