import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';


const LifeAtSepch = () => {
    return (
        <div className="container">
            <div className="row">
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Life at Southeast</BreadcrumbItem>
                </Breadcrumb>
                <div className="col-12">
                    <h3>Life at Southeast</h3>
                    <hr />
                </div>               
            </div>
            
            <div className="row">
                <div className="col-12" style={{textAlign:"center"}}>
                    <font size="6" color="green">SPRING/SUMMER NEWSLETTER 2023</font>
                </div>
            </div>
            <div><br></br></div>

            <div className="row">
                <div className="col-12" style={{textAlign:"center"}}>
                <font size="6" color="black">Ms. and Mr. Southeast</font>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture1.jpg" className="img-fluid photoborder" alt="Picture1"></img>
                </div>
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture2.jpg" className="img-fluid photoborder" alt="Picture2"></img>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{textAlign:"center", fontStyle:"italic"}}>
                    <p>Among the many, many things we’ve been up to this year, we crowned our very first Mr. and Ms. Southeast!  
                        You can see some of the photo’s our “judges” (family, Residents, and staff)  voted on inside, but our Ms. Southeast won by ONE vote, and Mr. 
                        Southeast by five votes!  Make sure to stop by and vote in the monthly contest we are running here!</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{textAlign:"center"}}>
                <font size="6" color="black">A Page of Smiles</font>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture3.jpg" className="img-fluid photoborder" alt="Picture3"></img>
                    <p style={{textAlign:"center"}}>H. Burston</p>
                </div>
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture4.jpg" className="img-fluid photoborder" alt="Picture4"></img>
                    <p style={{textAlign:"center"}}>P. Flett</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <img object src="asset/images/life-at-sepch/Picture5.jpg" className="img-fluid photoborder" alt="Picture5"></img>
                    <p style={{textAlign:"center"}}>B. Monias, S. Taylor, C. Taylor, G. Wood</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{textAlign:"center"}}>
                <font size="6" color="black">Easter Bunny Contest</font>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-4">
                    <img object src="asset/images/life-at-sepch/Picture6.jpg" className="img-fluid photoborder" alt="Picture6"></img>
                    <p style={{textAlign:"center"}}>L. Eaglestick</p>
                </div>
                <div className="col-12 col-md-4">
                    <img object src="asset/images/life-at-sepch/Picture7.jpg" className="img-fluid photoborder" alt="Picture7"></img>
                    <p style={{textAlign:"center"}}>S. Moar</p>
                </div>
                <div className="col-12 col-md-4">
                    <img object src="asset/images/life-at-sepch/Picture8.jpg" className="img-fluid photoborder" alt="Picture8"></img>
                    <p style={{textAlign:"center"}}>N. Nattaway</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12"  style={{textAlign:"center", fontStyle:"italic"}}>
                    <img object src="asset/images/life-at-sepch/Picture9.jpg" className="img-fluid photoborder" alt="Picture9"></img>
                    <p style={{textAlign:"center"}}>Our Winner, W. Sims!!!</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{textAlign:"center"}}>
                <font size="6" color="black">Ms. Southeast Competition Photo’s</font>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture10.jpg" className="img-fluid photoborder" alt="Picture3"></img>
                    <p style={{textAlign:"center"}}>S. Andrews</p>
                </div>
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture11.jpg" className="img-fluid photoborder" alt="Picture4"></img>
                    <p style={{textAlign:"center"}}>B. Campbell</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture12.jpg" className="img-fluid photoborder" alt="Picture3"></img>
                    <p style={{textAlign:"center"}}>C. Missyabit</p>
                </div>
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture13.jpg" className="img-fluid photoborder" alt="Picture4"></img>
                    <p style={{textAlign:"center"}}>B. Richards</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture14.jpg" className="img-fluid photoborder" alt="Picture3"></img>
                    <p style={{textAlign:"center"}}>T. Bittern</p>
                </div>
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture15.jpg" className="img-fluid photoborder" alt="Picture4"></img>
                    <p style={{textAlign:"center"}}>V. Campbell</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture16.jpg" className="img-fluid photoborder" alt="Picture3"></img>
                    <p style={{textAlign:"center"}}>K. Hilton</p>
                </div>
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture17.jpg" className="img-fluid photoborder" alt="Picture4"></img>
                    <p style={{textAlign:"center"}}>S. Courchene</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{textAlign:"center", fontStyle:"italic"}}>
                <font size="6" color="black">And Finally...</font>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture18.jpg" className="img-fluid photoborder" alt="Picture3"></img>
                </div>
                <div className="col-12 col-md-6">
                    <img object src="asset/images/life-at-sepch/Picture19.jpg" className="img-fluid photoborder" alt="Picture4"></img>
                </div>
            </div>
            <div className="row">
                <div className="col-12" style={{textAlign:"center", fontStyle:"italic"}}>
                    <p>Special thanks to the delegation from Norway and the Chancellor School for bringing joy and happiness during their visits to our place.  
                        We greatly appreciate being able to share our stories and learn from others!</p>
                </div>
            </div>
            <div className="row">
                <div className="col-12"  style={{textAlign:"center", fontStyle:"italic"}}>
                    <img object src="asset/images/life-at-sepch/Picture20.jpg" className="img-fluid photoborder" alt="Picture9"></img>
                    <p style={{textAlign:"center"}}>And Remember to keep smiling and dancing.</p>
                    <p style={{textAlign:"center", color:"red"}}>Love, Southeast Personal Care Home</p>
                </div>
            </div>
            <br></br>           
        </div>
    )
};

export default LifeAtSepch;


