import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem, Media, Button} from 'reactstrap';
import { Link } from 'react-router-dom';


class Covid19 extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>COVID & Infection Control</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>COVID & Infection Control Updates</h3>
                        <hr />
                    </div>               
                </div>

                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                        <h6>COVID-19 Information for Visitors</h6>
                        <a href="upload/files/Covid19_InfectionControls/covid-19-information-for-visitors.pdf" target="_blank" rel="noopener noreferrer">
                            <Button>
                                <i className="fa fa-download"></i> Download
                            </Button>
                        </a>
                        <p></p>
                        <h6>COVID-19 Long Term Care Visitor & Designated Caregiver IP&C Teaching Resource List</h6>
                        <a href="upload/files/Covid19_InfectionControls/covid-19-ltc-visitor-ipc-teaching-resource-list.pdf" target="_blank" rel="noopener noreferrer">
                            <Button>
                                <i className="fa fa-download"></i> Download
                            </Button>
                        </a>
                        <p></p>
                        <h6>COVID-19 Screening for Visitors</h6>
                        <a href="upload/files/Covid19_InfectionControls/covid-19-screening-poster-for-visitors.pdf" target="_blank" rel="noopener noreferrer">
                            <Button>
                                <i className="fa fa-download"></i> Download
                            </Button>
                        </a>
                        <p></p>
                        <h6>COVID-19 Cleaning Hand for Visitors</h6>
                        <a href="upload/files/Covid19_InfectionControls/covid-19-screening-poster-clean-hands-for-visitors.pdf" target="_blank" rel="noopener noreferrer">
                            <Button>
                                <i className="fa fa-download"></i> Download
                            </Button>
                        </a>
                        <p></p>
                        <h6>COVID-19 Precautions</h6>
                        <a href="upload/files/Covid19_InfectionControls/Precautions-to-follow for-Covid19.pdf" target="_blank" rel="noopener noreferrer">
                            <Button>
                                <i className="fa fa-download"></i> Download
                            </Button>
                        </a>
                        </Media>
                    </Media>
                    <br></br>
                </div>
                
               
            </div>
        );
    };
}

export default Covid19;