import React, {Component} from 'react';
import {Breadcrumb, BreadcrumbItem, Form, Label, Input, Col, Row, Button, Alert} from 'reactstrap';
import * as emailjs from 'emailjs-com';
import {init} from 'emailjs-com';
import {Link} from 'react-router-dom';


init("user_u3qlxtEBMJeQlMhIym3kh");

class SendWellness extends Component{

    constructor(props) {
        super(props);
        this.state ={
            sender_name:'',
            sender_email:'',
            resident_name:'',
            resident_room:'',
            message:'',
        }      
    }

    validateForm() {
        return this.state.sender_name.length > 0 && this.state.sender_email.length > 0 && 
               this.state.resident_name.length > 0 && this.state.message.length >0;
    }

    resetForm(){
        this.setState({
            sender_name:'',
            sender_email:'',
            resident_name:'',
            resident_room:'',
            message:'',

        })
    };

    handleChange = (param, e) => {
        this.setState({ [param] :e.target.value})
    };

    async handleSubmit (e){

        e.preventDefault();

        let templateParams ={
            sender_name: this.state.sender_name,
            sender_email: this.state.sender_email,
            resident_name: this.state.resident_name,
            resident_room: this.state.resident_room,
            message: this.state.message   
        }  
        
        await  emailjs.send('service_tnf1f1m', 'template_g0821ks', templateParams,'user_u3qlxtEBMJeQlMhIym3kh')
        .then(function(response) {
            alert('Your messages have been sent to our Recreation Staffs. Thank you', response.status, response.text);
         }, function(error) {
            alert('Failed to sent your message, please contact info@sepch.ca for more information', error);
         });

        this.resetForm();

    }

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Wellness </BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Wellness</h3>
                        <hr />
                    </div>               
                </div>
                <div className="col-12 mt-5">
                    <div className="col-12">
                        <h5>How it Works</h5>
                        <p>The purposes of this functionality is to let Families or Friends send their Wellness and Wishing messages to residents or our staffs.</p>
                        <p>The Recreation Staffs or SEPCH Volunteers will print and deliver "Well Wishes" to residents or staffs on a daily basis. Note that 
                           we sometimes can not deliver the messages in Weekends or Holidays, but it will be delivered right after our staffs are available.
                        </p>
                        <p>Send your love through new SEPCH program, and let your loved ones or staffs know you are thinking about them. This is meaningfull for all of us to overcome this difficult time.</p>
                        <p>This is our new program, so please do not hesitate to send any suggestions for the contents/functionalities that you would like to have and email to us at <a href="mailto:info@sepch.ca">info@sepch.ca</a></p>
                    </div>
                    <div className="col-12">
                        <h5>Send Wellness</h5>
                    </div>
                    <div className="col-12 col-md-9">
                        <Row>
                            <Col md={{size: 9}}>
                                <Alert color="warning">
                                    <i style={{color:'red'}}> * Fill in all fields until "Send Your Message" button is enabled</i>
                                </Alert>
                            </Col>
                        </Row>
                        <Form onSubmit={this.handleSubmit.bind(this)}>
                            <Row className="form-group">
                                <Label htmlFor="sender_name" md={9}>Your Name<i style={{color:'red'}}> *</i> :</Label>
                                <Col md={{size: 9}}>
                                    <Input type="text" 
                                           name="sender_name" 
                                           value={this.state.sender_name} 
                                           onChange={this.handleChange.bind(this,'sender_name')} 
                                           placeholder="First and Last Name" 
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="sender_email" md={9}>Your Email<i style={{color:'red'}}> *</i> :</Label>
                                <Col md={{size: 9}}>
                                    <Input type="email" 
                                           name="sender_email" 
                                           value={this.state.sender_email} 
                                           onChange={this.handleChange.bind(this,'sender_email')} 
                                           placeholder="Email" 
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="resident_name" md={9}>Resident's Name<i style={{color:'red'}}> *</i> :</Label>
                                <Col md={9}>
                                    <Input type="text" 
                                           name="resident_name" 
                                           value={this.state.resident_name} 
                                           onChange={this.handleChange.bind(this,'resident_name')}
                                           placeholder="Resident's Name" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="residents" md={9}>Resident's Room# :</Label>
                                <Col md={9}>
                                    <Input type="text" 
                                           name="resident_room"  
                                           value={this.state.resident_room} 
                                           onChange={this.handleChange.bind(this,'resident_room')}
                                           placeholder="Room #" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="message" md={9}>Your Message<i style={{color:'red'}}> *</i> :</Label>
                                <Col md={9}>
                                    <Input type="textarea" 
                                           rows="8" 
                                           name="message" 
                                           value={this.state.message} 
                                           onChange={this.handleChange.bind(this,'message')}
                                           placeholder="Your Wishing Messages"/>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col md={{size:10}}>
                                    <Button type="submit" color="success" disabled={!this.validateForm()}> Send Your Message</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </div>
        );
    };
}

export default SendWellness;