import React from 'react';
import {Link} from 'react-router-dom';

function Footer(props){
    return(
        <div className="footer">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-4 offset-1 col-sm-3">
                        <h5 style={{ color:"white" }}>Links</h5>
                        <ul className="list-unstyled" style={{ color:"white" }}> 
                            <li><Link to="/home" style={{ color:"white" }}>SEPCH</Link></li>
                            <li><Link to="/mission" style={{ color:"white" }}>About Us</Link></li>
                            <li><Link to="/admission" style={{ color:"white" }}>Residents</Link></li>
                            <li><Link to='/services' style={{ color:"white" }}>Services</Link></li>
                            <li><Link to='/recreationevents' style={{ color:"white" }}>Events</Link></li>
                            <li><Link to='/lifeatsepch' style={{ color:"white" }}>Life at SEPCH</Link></li>
                            <li><Link to='/careers' style={{ color:"white" }}>Careers</Link></li>
                            <li><Link to="/contactus" style={{ color:"white" }}>Contact Us</Link></li>
                        </ul>
                    </div>
                    <div className="col-7 col-sm-4">
                        <h5 style={{ color:"white" }}>Our Address</h5>
                        <address style={{ color:"white" }}>
		                    1265 Lee Blvd<br />
		                    Winnipeg, MB<br />
		                    CANADA R3T 2M3<br />
                            <i className="fa fa-phone fa-lg"></i>  +1 (204) 269-7111<br />
		                    <i className="fa fa-fax fa-lg"></i> +1 (204) 269-8819<br />
                        </address>
                    </div>
                    <div className="col-12 col-sm-4 align-self-center">

                        <div className="text-center">
                            <h5 style={{ color:"white" }}>Share This Page</h5>
                            <a className="btn btn-social-icon btn-facebook" href="http://www.facebook.com/profile.php?id="> <i className="fa fa-facebook"></i></a>
                            <i>&nbsp;</i>
                            <a className="btn btn-social-icon btn-linkedin" href="http://www.linkedin.com/in/"><i className="fa fa-linkedin"></i></a>
                            <i>&nbsp;</i>
                            <a className="btn btn-social-icon btn-twitter" href="http://twitter.com/"><i className="fa fa-twitter"></i></a>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                    <div className="col-auto">
                        <h6>© Copyright 2020 Southeast Personal Care Home</h6>
                    </div>
            </div>
                </div>
            </div>  
        </div>
    )
}

export default Footer;
