import React, {Component} from 'react';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {Link} from 'react-router-dom';


class Contact extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Contact Us</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Contact Us</h3>
                    </div>                
                </div>
                <div className="row row-content">
                    <div className="col-12">
                        <h3>Location Information</h3>
                    </div>
                    <div className="col-12 col-md-5">
                        <h5>Our Address</h5>
                        <address>We are located in the Fairfield Park neighborhood in Winnipeg and four blocks west of the University of Manitoba Campus Entrance.  
                                This facility is nestled in a quiet residential area, next door to Southeast College
                        </address>
                        <img src="asset/images/sepchcontact.jpg" className="img-fluid" height="270" width="440" alt="South East Personal Care Home"></img>        
                    </div>
                
                    <div className="col-12 col-md-7">
                        <h5>Map of Our Location</h5>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10301.359434936592!2d-97.17739423233527!3d49.79847797939079!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52c1df7d89dff3bb%3A0x7e6007722dfbb209!2sSoutheast%20Personal%20Care%20Home!5e0!3m2!1sen!2sca!4v1604073006705!5m2!1sen!2sca" 
                                style={{width:"100%", height:"92%", frameborder:"0", border:"0", allowfullscreen:""}} title="Southeast PCH Maps"></iframe>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <h5>Contact Information</h5>
                    </div>    
                    <div className="col-12 text-center">
                        <address>
                            1265 Lee Blvd
                            Winnipeg, MB
                            Canada<br />
                            <i className="fa fa-phone"></i> +1 (204) 269-7111<br />
                            <i className="fa fa-fax"></i> +1 (204) 269-8819<br />
                        </address>

                    </div>
                    <div className="col-12 text-center">
                        <h5>Management Team</h5>
                    </div>
                    <div className="col-12 text-center">
                        <h7>Kevin Friesen - Director of Care/Executive Director: <i className="fa fa-envelope"></i> <a href="mailto:kfriesen@sepch.ca">kfriesen@sepch.ca</a><br></br></h7>
                        <h7>Glecy Estacio - RN.BN Assistant Director of Care/Resident Care Manger: <i className="fa fa-envelope"></i><a href="mailto:gestacio@sepch.ca"> gestacio@sepch.ca</a><br></br></h7>
                        <h7>Adeola Izokun - Educator/Resident Care Manager: <i className="fa fa-envelope"></i><a href="mailto:aizokun@sepch.ca"> aizokun@sepch.ca</a><br></br></h7>
                        <h7>Kim Nguyen - HR/Finance Manager: <i className="fa fa-envelope"></i><a href="mailto:knguyen@sepch.ca"> knguyen@sepch.ca</a><br></br></h7>
                        <h7>Renee Gilbang - Social Worker: <i className="fa fa-envelope"></i><a href="mailto:rgilbang@sepch.ca"> rgilbang@sepch.ca</a><br></br></h7>
                        <h7>Dave Tran - Dietary Services Manager: <i className="fa fa-envelope"></i><a href="mailto:dtran@sepch.ca"> dtran@sepch.ca</a><br></br></h7>
                    </div>
                    <div className="col-12 text-center">
                        <h5><br></br>Business Hours</h5>
                    </div>
                    <div className="col-12 text-center">
                        <p>Office hours: Monday - Friday 8:30 - 16:30; Visiting Hours: 24/7</p>
                    </div>
                </div>
            </div>
        );
    };
}

export default Contact;