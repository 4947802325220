import React, {useState, useEffect} from 'react';
import Main from './Components/MainComponent'
import {BrowserRouter} from 'react-router-dom';
import {AppContext} from './libs/ContextLib';
import {Auth} from 'aws-amplify';
import './App.css';

function App () {

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  },[]);

  async function onLoad () {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user'){
        alert(e);
      }
    }
    setIsAuthenticating(false);
  }
  return (
    !isAuthenticating &&
      <BrowserRouter>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <Main isUserLogin={isAuthenticated}/>
        </AppContext.Provider>
      </BrowserRouter>
  );
}
export default App;
