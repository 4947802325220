import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem, Media } from 'reactstrap';
import { Link } from 'react-router-dom';


class Services extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Services</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Services</h3>
                        <hr />
                    </div>               
                </div>
                <Media>
                    <Media body className="ml-5">
                        <Media heading>Nursing</Media>
                        <p>Twenty-four hour nursing care is provided. All nurses are registered with the College of Registered Nurses, the College of Licensed Practical Nurses or the College of Registered Psychiatric Nurses. Health Care Aides have completed an accepted certificate program or one may be a student nurse in 2nd year of education.</p>
                    </Media>
                </Media>
                <Media>
                    <Media body className="ml-5">
                        <Media heading>Medical</Media>
                        <p>Each resident is assigned to the care of a regular attending physician at SEPCH unless arrangements have been made for your family physician to attend to you here. We have a Medical Director and guidelines for attending physicians. Emergency Medical Services (Ambulance) are contacted for acute medical emergencies</p>
                    </Media>
                </Media>
                <Media>
                    <Media body className="ml-5">
                        <Media heading>Elder/Spiritual Care Provider</Media>
                        <p>Provides support to all clients and staff in the organization in order to meet their spiritual and cultural needs. 
                            The Elder will ensure medicine wheel teachings are complimented for the facility
                           The Elder will also provide one to one traditional counselling, smudging ceremonies, name giving, doctoring and medicine tea’s as required. 
                           Part of the Elder’s duties include cultural awareness which includes teachings, sharing circles, ceremonies, grief and loss and a sacred fire.</p>
                    </Media>
                </Media>
                <Media>
                    <Media body className="ml-5">
                        <Media heading>Social Work Services</Media>
                        <p>Social work has played a significant role in the provision of care for persons in Long Term Care. 
                            Social work serves to ensure the autonomy and individuality of each resident. 
                            To ensure their voices are heard and to strengthen relationships with residents and their loved ones by connecting, listening, and communicating with openness and acceptance. 
                            Social work is imperative to maintaining optimal quality of life for residents by identifying the strengths of each individual and building on them to modify risks and seek solutions for optimal quality of life. 
                            The social worker is a multidisciplinary team member, participates in all ongoing care planning, and involves residents and their families to the furthest extent possible</p>
                        <p>Social work is the first contact a resident and their family will have and provides information about the facility, which include tours, policy discussions, and assessment of the residents’ needs. Social work maintains the personal care home’s waiting list and maintains contact with applicants and their families. 
                            As well as coordinates admissions and supports to the new residents and families through the transition process, helping them adjust to their new environment. 
                            Social work assists in clarifying concerns related to placement and care needs of the residents. 
                            Social work assists with offering services and resources as well as providing education to residents and family re advance care directives.</p>
                        <p>Social work helps family and residents navigate through government social programs and the health care system. 
                            Provide ease for the emotional and social impact of physical and mental illness or impairment. 
                            Supports at times of transition and loss, along with preservation and enhancement of physical and social functioning. 
                            Social work facilitates and work with the Interdisciplinary Health Team to ensure resident, and family participate in initial and yearly care conferences, and care planning. 
                            Social work help pave the path to work with residents and their family to facilitate the independence and right to self determination within the residents’ new home. 
                            External and internal advocacy, practical problem-solving support, and mediating concerns within the resident, family, and personal care home team.</p>
                    </Media>
                </Media>
                <Media>
                    <Media body className="ml-5">
                        <Media heading>Housekeeping</Media>
                        <p>Housekeeping staffs provide regular cleaning services</p>
                    </Media>
                </Media>
                <Media>
                    <Media body className="ml-5">
                        <Media heading>Laundry</Media>
                        <p>All clothing will be clearly labelled with the resident’s name.
                        SEPCH provides laundry services for resident’s personal clothing. WRHA Laundry Operations are used for all other items such as bedding and linen.</p>
                    </Media>
                </Media>
                <Media>
                    <Media body className="ml-5">
                        <Media heading>Hairdressing</Media>
                        <p>The Hair Salon at SEPCH is open at regularly scheduled times. Services are at the resident’s expense and may be charged through the resident’s trust account.</p>
                    </Media>
                </Media>
                <Media>
                    <Media body className="ml-5">
                        <Media heading>Dental/Denturist Services</Media>
                        <p>Services may obtained from the Home Dental Care Program.</p>
                    </Media>
                </Media>
                <Media>
                    <Media body className="ml-5">
                        <Media heading>Safety & Security</Media>
                        <p>All exits are coded. The front door is locked at 8:00 PM. Visitors sign in and out.  
                            Staff wear picture identification and enter or exit from the facility with a security card. 
                            All areas of facility are under video surveillance</p>
                    </Media>
                </Media>
                <Media>
                    <Media body className="ml-5">
                        <Media heading>Resident Council</Media>
                        <p>The Resident/Family Council meets on a monthly basis.
                                All residents have opportunity to share concerns, suggestions, and recommendations, which are addressed by appropriate persons. 
                                Family members and close friends of resident are welcome to join the meetings. 
                                Meeting Minutes are recorded and displayed on each house.</p>
                    </Media>
                </Media>
                <Media>
                    <Media body className="ml-5">
                        <Media heading>Activity Program</Media>
                        <p>The program aims to meet the physical, social, psychological and spiritual needs of each individual. 
                            To reach this goal, a variety of activities is planned. 
                            The program is coordinated by staff using volunteers.
                            Activities include exercises, seasonal parties, outings, shopping trips, baking, and others.</p>
                    </Media>
                </Media>
            </div>
        );
    };
}

export default Services;