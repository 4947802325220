import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem, Media, Button } from 'reactstrap';
import { Link } from 'react-router-dom';


class Admission extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Admission</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Admission</h3>
                        <hr />
                    </div>               
                </div>
                <div className="col-12 mt-5">
                        <Media>
                            <Media body className="ml-5">
                                <Media heading> Residents & Family Handbook </Media>
                                <a href="upload/files/Residents-Family/Resident-Family-Handbook.pdf" target="_blank">
                                    <Button>
                                        <i className="fa fa-download"></i> Download
                                    </Button>
                                </a>
                                <p></p>
                            </Media>
                        </Media>
                    </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <Media heading>Eligibility and Access</Media>
                            <p>Personal care homes are available for people who can no longer manage in their home and require 24-hour nursing care.  
                                Eligibility assessment and access is coordinated through Home Care and WRHA Long Term Access Centre.</p>
                            <p>Accessibility policies and information are available upon request. Please contact any of the <a href='https://www.southeastpch.ca/contactus'>Management Team</a> for more information if needed.</p>
                            <p>For more info, please contact the Long Term Care Access Centre at <a href="tel:204-833-1760">204-833-1760</a></p>
                        </Media>
                    </Media>
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <Media heading>Pre-Admission</Media>
                            <p>After we receive your application from the Long Term Care Access Centre, we will contact you. 
                                This provides an opportunity to confirm that we can provide the care you need. 
                                It is also a time for you to ask any questions you might have about living at SEPCH.
                                If you and your family are not familiar with the Home, you may make an appointment with the Admission Nurse for a tour. 
                                When you are notified that a room is available, a decision on acceptance must be made immediately. If you currently reside in another health care facility (hospital or PCH), you will be required to move in the day after notification. 
                                If you are residing in the community, the room will become yours the day after notification, but you have 5 days to complete the move, if desired. Upon admission, residents are usually assigned to one of the two doctors who come to SEPCH on a regular basis. 
                                In order to remain under the care of your current physician, he/she must be willing to visit regularly and must be readily available to staff (via phone or fax).</p>
                        </Media>
                    </Media>
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <Media heading>Admission</Media>
                            <p>When a room becomes available, you and/or your family will be contacted for acceptance and to arrange an admission time. 
                                On the day of admission, we encourage your family and/or friends to accompany you and spend time with you. 
                                We recognize that the first day may be stressful and hope that we can provide support to you. 
                                You will be given time to unpack and arrange your room. Your clothing will be labelled and returned to you. 
                                You are assigned a room based on your care needs. 
                                In time, you may be transferred from one room to another or one floor to another so that your needs can continue to be met. 
                                It is helpful that families support these changes when they are necessary. All rooms are single occupancy.</p>
                        </Media>
                    </Media>
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <Media heading>Some points to be aware of</Media>
                            <ul>
                                <li>You will be given a tour of your new home</li>
                                <li>You will have your picture taken for medical records</li>
                                <li>You will be shown your place in the dining room</li>
                                <li>You will be asked for information about your health and
                                    personal history because knowing something about your
                                    past and present experiences and likes and dislikes will
                                    enable us to assist you in adjusting to your new home</li>
                            </ul>
                        </Media>
                    </Media>
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <Media heading>Requested information</Media>
                            <ul>
                                <li>The name, address, and phone number of next of kin and finance contact</li>
                                <li>Arrangements in the event of death (e.g. funeral home)</li>
                                <li>Daily life routine and health history</li>
                            </ul>
                        </Media>
                    </Media>
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <Media heading>Admission Wait Times</Media>
                            <p>Please contact please contact Renee Gilbang at 204-269-7111 Ext. 116 for an approximate
                                wait time to come and live at SEPCH
                            </p>
                        </Media>
                    </Media>
                </div>
               
            </div>
        );
    };
}

export default Admission;