import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link } from 'react-router-dom';


const RecreationEvents = () => {
    return (
        <div className="container">
            <div className="row">
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                    <BreadcrumbItem active>Recreation Events</BreadcrumbItem>
                </Breadcrumb>
                <div className="col-12">
                    <h3>Recreation Events</h3>
                    <hr />
                </div>               
            </div>
            <div className="col-12 mt-5">
                <div className="col-12">
                    <h5 style={{textAlign:"center"}}>Recreation Events at SouthEast PCH</h5>
                    <p>Welcome to Recreation at South East Personal Care Home!  As much as we can, we will update this page with events and fabulous pictures of our amazing Residents and Elders.  
                        Stop and take a look, enjoy the people that we have the honor to work for and see what we’ve been up to!
                    </p>
                </div>
            </div>
            <div><br></br></div>
            <div className="row">
                <div className="col-12" style={{textAlign:"center", fontStyle:"italic"}}>
                    <h4>Happy Holidays from everyone here at Southeast Personal Care Home!</h4>

                </div>
            </div>
            <br></br>
            <div className="row photoborder">
                <div className="col-12 col-md-5 offset-md-1" style={{textAlign:"center",fontStyle:"italic"}} >
                    <img object src="asset/images/Activities-12-2022/001.jpg" className="img-fluid photoborder" width="400" alt="Picture2"></img>
                    <p></p>
                </div>
                <div className="col-12 col-md-5" style={{textAlign:"center", fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/002.jpg" className="img-fluid photoborder" width="400" alt="Picture3"></img>
                </div>
            </div>
            <br></br>
            <div className="row photoborder">
                <div className="col-12 col-md-5 offset-md-1" style={{textAlign:"center",fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/003.jpg" className="img-fluid photoborder" width="400" alt="Picture4"></img>
                    <p></p>
                </div>
                <div className="col-12 col-md-5" style={{textAlign:"center", fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/004.jpg" className="img-fluid photoborder" width="400" alt="Picture5"></img>
                    <p></p>
                </div>
            </div>
            <br></br>
            <div className="row photoborder">
                <div className="col-12 col-md-5 offset-md-1" style={{textAlign:"center",fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/005.jpg" className="img-fluid photoborder" width="400" alt="Picture6"></img>
                    <p></p>
                </div>
                <div className="col-12 col-md-5" style={{textAlign:"center", fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/006.jpg" className="img-fluid photoborder" width="400" alt="Picture7"></img>
                </div>
            </div>
            <br></br>
            <div className="row photoborder">
                <div className="col-12 col-md-5 offset-md-1" style={{textAlign:"center",fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/007.jpg" className="img-fluid photoborder" width="400" alt="Picture4"></img>
                    <p></p>
                </div>
                <div className="col-12 col-md-5" style={{textAlign:"center", fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/008.jpg" className="img-fluid photoborder" width="400" alt="Picture5"></img>
                    <p></p>
                </div>
            </div>
            <br></br>
            <div className="row photoborder">
                <div className="col-12 col-md-5 offset-md-1" style={{textAlign:"center",fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/009.jpg" className="img-fluid photoborder" width="400" alt="Picture4"></img>
                    <p></p>
                </div>
                <div className="col-12 col-md-5" style={{textAlign:"center", fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/010.jpg" className="img-fluid photoborder" width="400" alt="Picture5"></img>
                    <p></p>
                </div>
            </div>
            <br></br>
            <div className="row photoborder">
                <div className="col-12 col-md-5 offset-md-1" style={{textAlign:"center",fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/011.jpg" className="img-fluid photoborder" width="400" alt="Picture4"></img>
                    <p></p>
                </div>
                <div className="col-12 col-md-5" style={{textAlign:"center", fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/012.jpg" className="img-fluid photoborder" width="400" alt="Picture5"></img>
                    <p></p>
                </div>
            </div>
            <br></br>
            <div className="row photoborder">
                <div className="col-12 col-md-5 offset-md-1" style={{textAlign:"center",fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/013.jpg" className="img-fluid photoborder" width="400" alt="Picture4"></img>
                    <p></p>
                </div>
                <div className="col-12 col-md-5" style={{textAlign:"center", fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/014.jpg" className="img-fluid photoborder" width="400" alt="Picture5"></img>
                    <p></p>
                </div>
            </div>
            <br></br>
            <div className="row photoborder">
                <div className="col-12 col-md-5 offset-md-1" style={{textAlign:"center",fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/015.jpg" className="img-fluid photoborder" width="400" alt="Picture4"></img>
                    <p></p>
                </div>
                <div className="col-12 col-md-5" style={{textAlign:"center", fontStyle:"italic"}}>
                    <img object src="asset/images/Activities-12-2022/016.jpg" className="img-fluid photoborder" width="400" alt="Picture5"></img>
                    <p></p>
                </div>
            </div>
            <br></br>
            <br></br>
        </div>
    )
};

export default RecreationEvents;


