import React, {Component} from 'react';
import {Button, Breadcrumb, BreadcrumbItem, Media} from 'reactstrap';
import {Link} from 'react-router-dom';

class Volunteer extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Volunteer</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Volunteer</h3>
                        <hr />
                    </div>  
                    <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <p>Southeast Personal Care Home (SEPCH) is First Nation-owned and operated and respects the holistic Indigenous values and cultural traditions that nurture the health and well-being of each person. </p>
                            <p>SEPCH is actively looking for volunteers who can share their time and talents with our residents.  
                                We are seeking people to assist the Recreation department with programs, outings, bringing residents to and from activities, and visiting with those who cannot attend group programs.  
                                We are looking for people to read and visit with residents or to take them out for walks.  
                                It is a great opportunity to make a difference in someone’s life.</p>
                            <p>SEPCH promotes an atmosphere where the residents, families, staff, and volunteers feel at home. 
                                The activity department takes pride in offering a wide variety of programs meant to offer a sense of well being, and a sense of community. 
                                The goal is to offer an opportunity for residents to be involved and maintain life skills and interests.</p>
                            <p>
                            As volunteers at SEPCH you will be able to give and make a difference to many people.  
                            You will be there to lift spirits, to share your talents, and be a friend. 
                             Volunteering is rewarding, our staff and residents are very appreciative, and you will feel a sense of family. 
                             We also provide references for our volunteers upon request.</p>    
                            <p>
                            If you are interested in volunteering at SEPCH please contact Renee Gilbang or John Marlow at 204-269-7111 ext. 116, we will be so happy to discuss the volunteer opportunities 
                            OR email your application to Renee Gilbang at <a href="mailto:rgilbang@sepch.ca">rgilbang@sepch.ca</a> and John Marlow at <a href="mailto:jmarlow@sepch.ca">jmarlow@sepch.ca</a>.  
                            Some programs are not possible without volunteers to assist. 
                            If you are over 18 and wish to volunteer with us, please obtain and bring a copy of your 
                            <a href="https://www.gov.mb.ca/fs/adult_abuse_registry.html"> Adult Abuse Check</a> and <a href="https://legacy.winnipeg.ca/police/pr/pic.stm">Criminal record Check</a>.  
                            If you are under 18, please see attached guardian form in the handbook.
                            
                             It is the volunteers that can make the difference
                            </p>
                        </Media>
                    </Media>
                    </div>
                    <div className="col-12 mt-5">
                        <Media>
                            <Media body className="ml-5">
                                <h6>Volunteer Application Form</h6>
                                <a href="upload/files/VolunteerApplicationForm_v1.pdf" target="_blank" rel="noopener noreferrer">
                                    <Button>
                                        <i className="fa fa-download"></i> Download pdf
                                    </Button>
                                </a>
                                <p></p>
                                <a href="upload/files/Volunteer_Application_Form v1.docx" target="_blank" rel="noopener noreferrer">
                                    <Button>
                                        <i className="fa fa-download"></i> Download docx
                                    </Button>
                                </a>
                                <p></p>
                                <h6>Volunteer Handbook</h6>
                                <a href="upload/files/Volunteer_handbook_2023_latest.pdf" target="_blank" rel="noopener noreferrer">
                                    <Button>
                                        <i className="fa fa-download"></i> Download pdf
                                    </Button>
                                </a>
                            </Media>
                        </Media>
                    </div>              
                </div>
                <br></br>
            </div>
        );
    };
}

export default Volunteer;