import React from 'react';
import {Breadcrumb, BreadcrumbItem, Media} from 'reactstrap';
import Carousel from 'react-bootstrap/Carousel';
import {Link} from 'react-router-dom';

const Home = (props) => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to ="">SEPCH</Link></BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>
            <div className="caroulsecss">
                <Carousel>
                    <Carousel.Item>
                        <img className="d-block w-100 img-fluid photoborder"  src="asset/images/outdoor/outdoor1.jpg" alt="Outdoor 1"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100 img-fluid photoborder" src="asset/images/outdoor/outdoor5.jpg" alt="Outdoor 2"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100 img-fluid photoborder" src="asset/images/outdoor/outdoor3.jpg" alt="Outdoor 3"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100 img-fluid photoborder" src="asset/images/outdoor/outdoor4.jpg" alt="Outdoor 4"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100 img-fluid photoborder" src="asset/images/outdoor/outdoor2.jpg" alt="recreationroom"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100 img-fluid photoborder" src="asset/images/outdoor/residentroom.jpg" alt="residentroom"/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img className="d-block w-100 img-fluid photoborder" src="asset/images/outdoor/diningroom.jpg" alt="diningroom"/>
                    </Carousel.Item>
                </Carousel>
            </div>
            {/*
            <div className="col-12 col-sm m-1 row-content">
                <Media>
                    <Media left>
                        <Media object src="asset/images/Covid-19-Alert.jpeg" className ="photoborder" alt="Covid-19"  height="150" width="150" />
                    </Media>
                    <Media body className="ml-5">
                        <Media heading>NEW UPDATES</Media>
                        <p>SEPCH is following the COVID-19 situation very closely and can assure you we are following the latest directives to ensure everyone’s safety.</p>
                    </Media>
                </Media>
            </div>
            */}
            <div className="col-12 col-sm m-1 row-content">
                <Media>
                    <Media left>
                        <Media object src="asset/images/wellness.jpeg" className ="photoborder" alt="wellness"  height="150" width="150" />
                    </Media>
                    <Media body className="ml-5">
                        <Media heading>Wellness Messages & Visit Booking </Media>
                        <p>Families/Friends can now send wellness messages to your loved ones/our staffs and book your visits using our online portal.</p>
                        <p>We are now accepting external shelter visit booking.</p>
                        <Link to ="/sendwellness">Send Wellness Messages</Link>
                        <br></br>
                        <Link to ="/visitbooking">Online Visit Booking</Link>   
                    </Media>
                </Media>
            </div>
            <div className="col-12 col-sm m-1 row-content-last ">
                <Media>
                    <Media left>
                        <Media object src="asset/images/Hotline.jpeg" className ="photoborder" alt="Covid-19"  height="150" width="150" />
                    </Media>
                    <Media body className="ml-5">
                        <Media heading>SEPCH Patient & Resident Hotline</Media>
                        <p>If you would like to make inquiries about Resident at SEPCH, please call us at 204-269-7111.
                           Calls to this line will be returned daily Monday – Friday</p>
                    </Media>
                </Media>
            </div>            
        </div>
    );
} 


export default Home;