import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem, Media, Button } from 'reactstrap';
import { Link } from 'react-router-dom';


class Accessibility extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Accessibility</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Accessibility</h3>
                        <hr />
                    </div>               
                </div>
                <div className="col-12 mt-5">
                        <Media>
                            <Media body className="ml-5">
                                <Media heading>Policies</Media>
                                <a href="upload/files/Residents-Family/APM-ADMIN-06-WRHA-Accessibility-for-Persons-with-Disabilities-Customer-Services.pdf" target="_blank">
                                    <Button>
                                        <i className="fa fa-download"></i> WRHA Accessibility for Persons with Disabilities
                                    </Button>
                                </a>
                                <p></p>
                                <a href="upload/files/Residents-Family/APM-ADMIN-09-Accessible-Empolyment.pdf" target="_blank">
                                    <Button>
                                        <i className="fa fa-download"></i> Accessible Employment
                                    </Button>
                                </a>
                            </Media>
                        </Media>
                    </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <p>We are committed to complying with the Accessibility Standard under The Accessibility for Manitobans Act. 
                                Our policies, practices and measures reflect principles of dignity, independence, integration and equal opportunity for people with disabilities.
                                We aim to remove barriers at Southeast PCH. If a barrier cannot be removed, we seek to provide reasonable accommodations to affected individuals.</p>
                            <p>If you, a family member or any of your visitors need assistance with any accessibility issues whether they be related to physical barriers, 
                                communication or any other area of concern please contact Management, the Social Worker or any employee.
                                Accessibility policies are available upon request.</p>
                        </Media>
                    </Media>
                </div>
            
            </div>
        );
    };
}

export default Accessibility;