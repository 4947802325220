import React from 'react';
import Contact from "./ContactComponent";
import Home from './HomeComponent';
import Header from './HeaderComponent';
import Footer from './FooterComponent';
import {Switch, Route, Redirect} from 'react-router-dom';
import Login from './LoginComponent';
import Career from './CareerComponent';
import Services from './ServicesComponent';
import Admission from './AdmissionComponent';
import Job from './JobComponent';
import ResidentSafety from './ResidentSafety';
import Activities from './ActivitiesComponent';
import Mission from './MissionComponent';
import LoginHome from './LoginHomeComponent';
import Internaljob from './InternalJobComponent';
import InternalBulletin from './InternalBulletinComponent';
import InternalHome from './InternalHomeComponent';
import Volunteer from './VolunteerComponent';
import SendWellness from './SendWellnessComponent';
import Donation from './DonationComponent';
import VisitBooking from './VisitBookingComponent';
import Accessibility from './AccessibilityComponent';
import LifeAtSepch from './LifeAtSepch';
import RecreationEvents from './RecreationEvents';
//import Celebration from './CelebrationOfLifeComponent';
import ResidentDetail from './ResidentsDetailComponent';
import Covid19 from './Covid19Component';
import ExternalBulletin from './ExternalBulletinComponent';
import data from "./data"; 


const Main = (props) => {
  
  
  if (props.isUserLogin){
    return(
      <div>
        <LoginHome></LoginHome>
        <Switch>
          <Route exact path ='/internalhome' component={InternalHome}></Route>
          <Route exact path ='/internaljob' component={Internaljob}></Route>
          <Route exact path ='/internalbulletin'component={InternalBulletin} ></Route>
          <Redirect to="/internalhome"></Redirect> 
        </Switch>
      </div>
    );
  }
  else {

    const renderRes = (routerProps) => {
      let resId = (routerProps.match.params.id)
      let foundRes = data.resident.find(item => item.id === resId)
      return (foundRes ? <ResidentDetail res={foundRes}/> : <NotFound/>)
    }
    const NotFound = () => {
      return (
          <div>
              Can not find resident information
          </div>
      )
  }

    return(
      <div>
          <Header></Header>
          <Switch>
              <Route path="/home" component={Home}></Route>
              <Route exact path="/contactus" component={Contact}></Route>
              <Route exact path ='/login' component={Login}></Route>
              <Route exact path ='/careers' component={Career}></Route>
              <Route exact path ='/services' component={Services}></Route>
              <Route exact path ='/admission' component={Admission}></Route>
              <Route exact path ='/accessibility' component={Accessibility}></Route>
              <Route exact path ='/job' component={Job}></Route>
              <Route exact path ='/residentsafety' component={ResidentSafety}></Route>
              <Route exact path ='/activities' component={Activities}></Route>
              <Route exact path ='/mission' component={Mission}></Route>
              <Route exact path ='/volunteer' component={Volunteer}></Route>
              <Route exact path ='/sendwellness' component={SendWellness}></Route>
              <Route exact path ='/donation' component={Donation}></Route>
              <Route excat path ="/visitbooking" component={VisitBooking}></Route>
              <Route excat path ="/bulletin" component={ExternalBulletin}></Route>
              <Route excat path ="/lifeatsepch" component={LifeAtSepch}></Route>
              <Route excat path ="/recreationevents" component={RecreationEvents}></Route>
              <Route excat path ="/covid-19-updates" component={Covid19}></Route>
              {/*
              <Route excat path ="/celebrationoflife" component={Celebration}></Route>
              <Route exact path ="/celebrationoflife1/:id" render = {routerProps => renderRes(routerProps)} />
              */
              }
              <Redirect to="/home"></Redirect> 
          </Switch>
          <Footer></Footer>
      </div>
    );
  }
}

export default Main;