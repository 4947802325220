import React, {Component} from 'react';
import {Breadcrumb, BreadcrumbItem, Media, Button} from 'reactstrap';
import { Link } from 'react-router-dom';

class InternalJob extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/internalhome">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Job</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Job</h3>
                        <hr />
                    </div>   
                    <div className="col-12 mt-5">
                        <Media>
                            <Media body className="ml-5">
                                <h6>Internal Job Application Form</h6>
                                <Link to="upload/files/InternalApplication.pdf" target="_blank" rel="noopener noreferrer">
                                    <Button>
                                        <i className="fa fa-download"></i> Download pdf
                                    </Button>
                                </Link>
                                <p></p>
                                <Link to="upload/files/InternalApplication.docx" target="_blank" rel="noopener noreferrer">
                                    <Button>
                                        <i className="fa fa-download"></i> Download docx
                                    </Button>
                                </Link>
                            </Media>
                        </Media>
                    </div>             
                </div>
            </div>
        );
    };
}

export default InternalJob;