import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem, Media } from 'reactstrap';
import { Link } from 'react-router-dom';


class Donation extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Donation</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Donation</h3>
                        <hr />
                    </div>               
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <p>Winnipeg, MB December 02, 2020</p>
                            <p>Dear Families,</p>
                            <p>As most of you are aware, we usually have a Silent Auction every Christmas to raise funds for our Indigenous Cultural Committee to purchase things for our Residents.  
                                We would still like to hold this event as the Residents and staff really enjoy this.  
                                We are asking if families could make a donation for our Auction as we have limited shopping abilities. 
                                The Residents always enjoy toiletries, treats like potato chips, perhaps gift cards to places like Tim Horton’s or other fast food establishments.  
                                Even lotto tickets would be a nice prize.</p>
                            <p>Please consider this for our Resident’s and staff.  If you can make a donation please contact Rita at 204-269-7111, ext 2225.</p>
                            <p>Thank You</p>
                        </Media>
                    </Media>
                </div>
            </div>
        );
    };
}

export default Donation;