import React, {Component} from 'react';
import {Breadcrumb, BreadcrumbItem, Form, Label, Input, Col, Row, Button, Alert} from 'reactstrap';
import * as emailjs from 'emailjs-com';
import {init} from 'emailjs-com';
import {Link} from 'react-router-dom';


init("user_u3qlxtEBMJeQlMhIym3kh");

class VisitBooking extends Component{

    constructor(props) {
        super(props);
        this.state ={
            sender_name:'',
            sender_email:'',
            resident_name:'',
            resident_room:'',
            visit_type:'',
            visit_date:'',
            phone:'',
            visit_time:'',
            message:'',
        }      
    }

    validateForm() {
        return this.state.sender_name.length > 0 && this.state.sender_email.length > 0 && 
               this.state.resident_name.length > 0 && this.state.visit_date.length > 0 && 
               this.state.visit_time.length > 0 && this.state.visit_type.length > 0;
    }

    resetForm(){
        this.setState({
            sender_name:'',
            sender_email:'',
            resident_name:'',
            resident_room:'',
            visit_type:'',
            visit_date:'',
            visit_time:'',
            phone:'',
            message:'',

        })
    };
    
    handleChange = (param, e) => {
        this.setState({ [param] :e.target.value})
    };

    async handleSubmit (e){

        e.preventDefault();

        let templateParams ={
            sender_name: this.state.sender_name,
            sender_email: this.state.sender_email,
            resident_name: this.state.resident_name,
            resident_room: this.state.resident_room,
            visit_type:this.state.visit_type,
            visit_date:this.state.visit_date,
            visit_time:this.state.visit_time,
            phone:this.state.phone,
            message: this.state.message   
        }  
        
        await  emailjs.send('service_tnf1f1m', 'template_6f6lpcr', templateParams,'user_u3qlxtEBMJeQlMhIym3kh')
        .then(function(response) {
            alert('Your Booking has been sent to our Recreation Staffs. The Staffs will contact you to confirm your visit. Thank you', response.status, response.text);
         }, function(error) {
            alert('Failed to submit your booking, please contact info@sepch.ca for more information', error);
         });

        this.resetForm();

    }

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Visit Booking </BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Visit Booking</h3>
                        <hr />
                    </div>               
                </div>
                <div className="col-12 mt-5">
                    <div className="col-12">
                        <h5 style={{textAlign:"center"}}>Visitation at SouthEast Personal Care Home</h5>
                        <p>Hello, welcome!  During these challenging times, we have tried to do everything we can to make visiting your loved one not only a priority, but as easy as possible.  Please read the guidelines below and make sure to follow them for the safety of your loved one.
                        </p>
                        <p style={{textAlign:"center", textDecoration:"underline"}}>Virtual Visit</p>
                        <p>Virtual visits are also available. You will need to send your Skype information (email address/username), zoom information or Google Duo information.  We do NOT have messenger or Facetime.</p>
                        <p><strong>These will be 30 minute visits, available Tuesday-Thursday at 11:15 AM, 1:15 PM, 3:15 PM, Friday & Monday at 11:15 AM, 1:15 PM.</strong></p>
                        <p>You will hear back from Tanya (<a href="mailto:TRoss@sepch.ca">TRoss@sepch.ca</a>), John (<a href="mailto:jmarlow@sepch.ca">jmarlow@sepch.ca</a>) or  Navjeet (<a href="mailto:vsohal@sepch.ca">vsohal@sepch.ca</a>) with confirmation, or if the time is taken, asking for a different time.</p>
                        <p>If you are booking multiple slots please make sure the first one you send is the most important.  We must try to be fair to all families, especially those coming in from out of town.  That said we will take each booking as much in order as we can.</p>
                        {/*
                        <p style={{textAlign:"center", textDecoration:"underline"}}>If you are booking a visit for our visitation center:</p>
                        <ul>
                            <li>Procedure Masks will be provided and worn at all times.</li>
                            <li>No food or drink for yourself, or for your loved one, will be allowed inside.</li>
                            <li>No pets, no smoking, no vaping will be allowed.</li>
                            <li>Please do not pass anything to your loved one, or have them pass you anything.  Any drop-offs can be done by calling the Unit directly and arranging a time for drop-off at the back door of the building.</li>
                        </ul>
                        <p style={{textAlign:"center", textDecoration:"underline"}}>What to expect when you arrive:</p>
                        <ul>
                            <li>Please come around the corner to our visitation center.  We have a ramp for anyone that needs.</li>
                            <li>Doors will be locked until visiting time at the top of the hour.  Please arrive on time as early arrivals will be waiting outside.</li>
                            <li>When you enter you will be screened and have your temperature taken.  Please answer all questions honestly and fully no matter how many times you’ve heard them.</li>
                            <li>Visits will last no more than 30 minutes so we have time to take your loved one back, clean the entire visitation center and get the next person for the next visit.</li>
                        </ul>
                        <p><strong>As of April 8th 2021, family and general visitors are allowed into the visitation center.</strong></p>

                        <p style={{textAlign:"center", textDecoration:"underline"}}>Visitation Center Visit</p>
                        <p><strong>Bookings times are seven days a week at the following times: 12(noon), 1 PM, 2 PM, 4 PM, 5 PM, 6 PM, 7 PM.  
                                 Bookings for the weekend MUST be in by 2:30PM on that Friday or they will not be accommodated in time.</strong></p>        
        */}
                    </div>
                    {/*
                    <div className="col-12">
                        <h5>Schedule Your Visits</h5>
                    </div>
                    */}
                    {/*
                    <div className="col-12 col-md-9">
                        <Alert color="warning">
                            <h5 className="alert-heading">Visit Booking Notes</h5>
                            <p>
                                 As for December 24th at noon, no booking emails will be processed until Monday, December 28th.  We are sorry for any inconvenience.
                            </p>
                        </Alert>
                        <Alert color="warning">
                            <i style={{color:'red'}}> * Please read visit booking guideline carefully before scheduling your visits. Fill in all fields until "Submit Your Booking" button is enabled</i>
                        </Alert>
                        <Form onSubmit={this.handleSubmit.bind(this)}>
   
                            <Row className="form-group">
                                <Label htmlFor="sender_name" md={9}>Your Name<i style={{color:'red'}}> *</i> :</Label>
                                <Col md={{size: 9}}>
                                    <Input type="text" 
                                           name="sender_name" 
                                           value={this.state.sender_name} 
                                           onChange={this.handleChange.bind(this,'sender_name')} 
                                           placeholder="First and Last Name" 
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="sender_email" md={9}>Your Email<i style={{color:'red'}}> *</i> :</Label>
                                <Col md={{size: 9}}>
                                    <Input type="email" 
                                           name="sender_email" 
                                           value={this.state.sender_email} 
                                           onChange={this.handleChange.bind(this,'sender_email')} 
                                           placeholder="Email" 
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="sender_email" md={9}>Your Phone Number :</Label>
                                <Col md={{size: 9}}>
                                    <Input type="text" 
                                           name="phone" 
                                           value={this.state.phone} 
                                           onChange={this.handleChange.bind(this,'phone')} 
                                           placeholder="Phone Number" 
                                    />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="resident_name" md={9}>Resident's Name<i style={{color:'red'}}> *</i> :</Label>
                                <Col md={9}>
                                    <Input type="text" 
                                           name="resident_name" 
                                           value={this.state.resident_name} 
                                           onChange={this.handleChange.bind(this,'resident_name')}
                                           placeholder="Resident's Name" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="residents" md={9}>Resident's Room# :</Label>
                                <Col md={9}>
                                    <Input type="text" 
                                           name="resident_room"  
                                           value={this.state.resident_room} 
                                           onChange={this.handleChange.bind(this,'resident_room')}
                                           placeholder="Room #" />
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="residents" md={9}>Select Visit Type <i style={{color:'red'}}> *</i> :</Label>
                                <Col md={9}>
                                    <Input type="select" 
                                           name="visit_type"  
                                           value={this.state.visit_type} 
                                           onChange={this.handleChange.bind(this,'visit_type')}
                                           placeholder="Visit Type">
                                        <option value="">Please select visit type</option>
                                        <option value="Visitation Center Visit" default>Visitation Center Visit</option>
                                        <option value="Virtual Visit">Virtual Visit</option>
                                    </Input>    
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Label htmlFor="residents" md={9}>Visit Date<i style={{color:'red'}}> *</i> :</Label>
                                <Col md={9}>
                                    <Input type="date" 
                                           name="visit_date"  
                                           value={this.state.visit_date} 
                                           onChange={this.handleChange.bind(this,'visit_date')}>
                                    </Input>    
                                </Col>
                            </Row>
                                this.state.visit_type === 'Visitation Center Visit' ?
                                <Row className="form-group">
                                    <Label htmlFor="residents" md={9}>Visit Time <i style={{color:'red'}}> *</i> :</Label>
                                    <Col md={9}>
                                        <Input type="select" 
                                            name="visit_time"  
                                            value={this.state.visit_time} 
                                            onChange={this.handleChange.bind(this,'visit_time')}>
                                            <option value="" defaultValue>Please select visit time</option>
                                            <option value="12 PM">12 PM</option>
                                            <option value="1 PM" >1 PM</option>
                                            <option value="2 PM" >2 PM</option>
                                            <option value="4 PM" >4 PM</option>
                                            <option value="5 PM" >5 PM</option>
                                            <option value="6 PM" >6 PM</option>
                                            <option value="7 PM" >7 PM</option>
                                        </Input>    
                                    </Col>
                                </Row> :
                                <Row className="form-group">
                                    <Label htmlFor="residents" md={9}>Visit Time<i style={{color:'red'}}> *</i> :</Label>
                                    <Col md={9}>
                                        <Input type="time" 
                                            name="visit_time"  
                                            value={this.state.visit_time} 
                                            onChange={this.handleChange.bind(this,'visit_time')}>
                                        </Input>    
                                    </Col>
                                </Row>
                                <Row className="form-group">
                                <Label htmlFor="residents" md={9}>Visit Time<i style={{color:'red'}}> *</i> :</Label>
                                <Col md={9}>
                                    <Input type="time" 
                                           name="visit_time"  
                                           value={this.state.visit_time} 
                                           onChange={this.handleChange.bind(this,'visit_time')}>
                                    </Input>    
                                </Col>
                                </Row>
        
                            <Row className="form-group">
                                <Label htmlFor="message" md={9}>Addtional Information :</Label>
                                <Col md={9}>
                                    <Input type="textarea" 
                                           rows="8" 
                                           name="message" 
                                           value={this.state.message} 
                                           onChange={this.handleChange.bind(this,'message')}
                                           placeholder="Additional information (skype ID, Zoom, Special needs ect...)"/>
                                </Col>
                            </Row>
                            <Row className="form-group">
                                <Col md={{size:10}}>
                                    <Button type="submit" color="success" disabled={!this.validateForm()}> Submit Your Booking</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    */}
                </div>
            </div>
        );
    };
}

export default VisitBooking;