import React from 'react';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {Link} from 'react-router-dom';

const Career = (props) => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to ="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Careers</BreadcrumbItem>
                    </Breadcrumb>
                </div>
            </div>
            <div className="col-12">
                <img className="img-fluid photoborder" id ="img-career" style={{ height:'60%', width:"70%"}} src="asset/images/career/staffpictues.jpg" alt="Staff"></img>
                <p>
                    <strong><br></br>Employment Notice</strong>
                </p>
                <p>
                    Southeast Personal Care Home is looking for applicants in the fields of:
                </p>
                <ul>
                    <li>Health Care Aide</li>
                    <li>License Practical Nurse</li>
                    <li>Registered Nurse</li>
                    <li>Dietary</li>
                    <li>Housekeeping</li>
                </ul>
                <p>Please send resumes to:</p>
                <address>
                Glecy Estacio - RN.BN Assistant Director of Care/Resident Care Manger at <a href="mailto:gestacio@sepch.ca"> gestacio@sepch.ca</a> or Kim Nguyen - HR/Finance Manager at <a href="mailto:knguyen@sepch.ca"> knguyen@sepch.ca</a>
                </address>

                <p>
                All Indigenous applicants are encouraged to voluntarily self identify. SERDC recognizes the importance of building
                an explemplary service delivery organization that is equal, inclusive and reflective of the population it serves. Preference will be given to qualified SERDC of First Nation members who voluntarily self-declare in their application.
                </p>
            </div>
            
            <div className="col-12">
            <   p><Link to='/job'>Job Posting</Link></p>
            </div>
        </div>
    );
} 
export default Career;