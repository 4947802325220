import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem, Media } from 'reactstrap';
import { Link } from 'react-router-dom';


class ExternalBulletin extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/home">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Bulletin</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Bulletin</h3>
                        <hr />
                    </div>               
                </div>
                <div className="col-12 mt-5">
                    <Media>
                        <Media body className="ml-5">
                            <p>Winnipeg, MB December 29, 2021</p>
                            <p>Dear Family Members,</p>
                            <p>As you are aware there has been a significant increase in positive COVID cases throughout the community. 
                                There is a number of PCH’s now back in outbreaks. 
                                The Omicron variant has proven to spread at rates that we have not encountered before with case counts setting records almost on a daily basis. 
                                We must take some further steps to protect our Elders, staff and visitors..</p>
                            <p>Effective tomorrow we will be suspending all in room visits. 
                            General visitation will be completely suspended and the 2 designated visitors for each Elder can schedule visitation in the external visitation shelter by calling reception.
                            Effective immediately all Resident social leaves are suspended as risk is just too high.</p>
                            <p>We understand the difficulties this places on you and your loved ones and do not take a decision like this lightly. 
                                My hopes is that this will be a short term safety measure to get through the worst period of this wave. 
                                We will reassess on January 14th.</p>
                            <p>Thank you for all your support and anticipated cooperation. It is greatly appreciated. Please feel free to reach out to me with any questions or concerns</p>
                            <p>Kindest Regards</p>
                            <i>Kevin Friesen - Director of Care/Executive Director</i>
                            <p></p>
                        </Media>
                    </Media>
                </div>
            </div>
        );
    };
}

export default ExternalBulletin;