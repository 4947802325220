import React, {Component} from 'react';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import { Link } from 'react-router-dom';

class InternalBulletin extends Component{

    render(){
        return(
            <div className="container">
                <div className="row">
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/internalhome">SEPCH</Link></BreadcrumbItem>
                        <BreadcrumbItem active>Bulletin</BreadcrumbItem>
                    </Breadcrumb>
                    <div className="col-12">
                        <h3>Bulletin</h3>
                        <hr />
                    </div>               
                </div>
            </div>
        );
    };
}

export default InternalBulletin;